import {
  IPage,
  IPost,
  IPagination,
  ILocation,
  ICategory
} from '@standardTypes';

import React from 'react';

import { Layout } from '@components/layout';
import {
  BlogHero,
  CategoryPosts,
  SectionsWithBackround
} from '@components/organisms/standard';

interface BlogProps {
  pageContext: {
    page: IPage;
    posts: Array<IPost>;
    pagination: IPagination;
    categories: ICategory[];
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const ArticlesPage: React.FC<BlogProps> = ({
  pageContext,
  location
}): JSX.Element => {
  const { page, pagination, posts, translationUrls } = pageContext;
  const { skip, limit, currentPage } = pagination;
  const { pathname } = location;

  const currentPagePosts = posts.slice(skip, limit * currentPage);
  const backgroundTiles = currentPagePosts.length > 7 ? 4 : 3;

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <BlogHero activeLink="articles" />
      <SectionsWithBackround numberOfBackgrounds={backgroundTiles}>
        <CategoryPosts
          posts={currentPagePosts}
          activeLink="articles"
          pagination={pagination}
          pathname={pathname}
        />
      </SectionsWithBackround>
    </Layout>
  );
};
export default ArticlesPage;
